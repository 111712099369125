<template>
  <div class="login_box pos-r" v-show="states.type=='3'">
    <div class="color333 fz24 fw600 title_font">申请试用钜汇</div>
    <input class="admin_input color333 fz16" v-model="code" name="code" autocomplete="off" placeholder="输入您的验证码" type="text">
    <input class="admin_input color333 fz16" v-model="business" name="business" autocomplete="off" placeholder="输入您的企业名称" type="text">
    <input class="admin_input color333 fz16" v-model="user_name" name="user_name" autocomplete="off" placeholder="输入您的姓名" type="text">
    <div class="login_btn_box dis-flex4">
      <div class="login_btn_box_center">
        <div class="login_btn login_btn_next center color-fff" @click="toSubmit">确认提交</div>
        <div class="center color-blue fz14 login_font" @click="loginBtn">我要登录</div>
      </div>
    </div>
  </div> 
</template>

<script>
import { reactive,toRefs } from 'vue'
import { addUser } from '@/api/login'
import {useRouter } from 'vue-router'
import { ElNotification } from 'element-plus'
export default {
  name:'Appliadmin',
  props:{
    states:Object,
  },
  setup(props,ctx){
    const router = useRouter()
    const loginBtn=()=>{
      ctx.emit('changeTypes','1')
      // router.push({ path: '/Login',query:{type:1}})
    }
    const state = reactive({
      code:'',
      business:'',
      user_name:''
    })
    const toSubmit=async()=>{
      await addUser({
        "code": state.code,
        "business": state.business,
        "user_name":state.user_name,
        "phone":props.states.phone
      })
      ElNotification({
        title: 'Success',
        message: '申请成功',
        type: 'success',
        duration: 3000,
      })
      router.push({ path: `/` })
    }
    return {...toRefs(state),loginBtn,toSubmit}
  }
  
}
</script>

<style lang="scss" scoped>
  .login_box{
    padding:60px;
    height:550px;
    box-sizing: border-box;
    .title_font{
      margin-bottom:32px;
    }
    .navigation_index{
      padding-top:32px;
    }
    
    .login_input{
      padding-top:22px;
    }
    .login_btn_box{
      position: absolute;
      bottom:60px;
      right:0;
      left:0;
      .login_btn_box_center{
        width:380px;
      }
      .login_btn{
        background:#0F6FFF;
        padding:12px 0;
        border-radius: 6px;
        cursor: pointer;
      }
      .login_font{
        cursor: pointer;
      }
      .yes_check{
        margin-left:10px;
      }
      .login_btn_next{
        margin:20px 0 16px 0;
      }
    }
    .admin_phone{
      margin-top:32px;
    }
    .admin_input{
      width:100%;
      border:1px solid #DDDEE1;
      padding:12px 16px;
      margin-bottom:22px;
      color:#999;
      border-radius: 6px;
    }
    .admin_input:focus{
      outline:none;
      border:1px solid #0F6FFF ;
      color:#333
    }
  }
</style>