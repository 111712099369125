<template>
  <div class="content dis-flex4 dis-flex3 pos-r">
    <img class="content_img" :src="require('@/assets/img/bg_di.png')" alt="">
    <div class="logo_img" @click="urlHome"><img class="" :src="require('@/assets/img/logo1.png')" alt=""></div>
    <div class="dis-flex">
      <div class="tu_icon"><img src="https://fmcg.oss-cn-hangzhou.aliyuncs.com/pc/tu.png" alt=""></div>
      <div class="tu_right">
        <!-- 品牌登录 -->
        <Loginadmin :types="states.type" @changeTypes="changeTypes"></Loginadmin>
        <Useradmin :types="states" @changeTypes="changeTypes"></Useradmin>
        <Appliadmin :states="states" @changeTypes="changeTypes"></Appliadmin>
        <!-- <div v-show="Number(states.type)==1"></div>
        <div v-show="Number(states.type)==2"></div>
        <div v-show="Number(states.type)==3"></div> -->
        <!-- <div class="login_box pos-r">
          <div class="color333 fz24 fw600 title_font">申请试用钜汇</div>
          <input class="admin_input fz16" placeholder="输入您的验证码" type="text">
          <input class="admin_input fz16" placeholder="输入您的企业名称" type="text">
          <input class="admin_input fz16" placeholder="输入您的姓名" type="text">
          <div class="login_btn_box dis-flex4">
            <div class="login_btn_box_center">
              <div class="login_btn login_btn_next center color-fff">下一步</div>
              <div class="center color-blue fz14">我要登录</div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import Loginadmin from '@/components/Loginadmin'
import {useRoute,useRouter } from 'vue-router'
import Useradmin from '@/components/Useradmin'
import { onMounted, reactive} from 'vue'
import Appliadmin from '@/components/Appliadmin'
export default {
  name:'Login',
  components:{
    Loginadmin,
    Useradmin,
    Appliadmin
  },
  setup(){
    const route = useRoute()
    const router = useRouter()
    const state={
      type:'1',
      phone:'',
    }
    const states=reactive(state)
    // if(route.params){
    //   states.type=route.params.type
    //   states.phone=route.params.search_value
    // }else if(route.query){
    states.type=route.query.type
    if(route.query.search_value){
      states.phone=route.query.search_value
    }
    const changeTypes=(value,phone)=>{
      if(phone){
        states.phone=phone
      }
      states.type=value
    }
    const urlHome=()=>{
      router.push({path: '/'})
    }
    onMounted(()=>{
      if(route.query.offWeb=="false"){
        localStorage.removeItem('dataValue')
        localStorage.removeItem('typeData')
        localStorage.removeItem('api_token')
      }
    })
    return {states,changeTypes,urlHome}
  }
}
</script>

<style lang="scss" scoped>
  .content{
    height:100vh;
    background-size:100% 100%;
    .content_img{
      position: absolute;
      width: auto;
      max-width: 100%;
      height: auto;
      min-height: 100%;
      vertical-align: middle;
      z-index: -1;
    }
    .logo_img{
      width:160px;
      height:36px;
      position: absolute;
      top:30px;
      left:40px;
      cursor: pointer;
    }
    .tu_icon{
      width:500px;
      height: 550px;
    }
    .tu_right{
      width:500px;
      background:#fff;
      border-radius: 0 16px 16px 0;
    }
  }
</style>