<template>
  <div class="login_box pos-r" v-show="types.type=='2'">
    <div class="color333 fz24 fw600">欢迎使用钜汇</div>
    <input class="admin_input admin_phone color333 fz16" v-model="phone" autocomplete="off" name="phone" placeholder="输入您的手机号" type="text">
    <div class="login_btn_box dis-flex4">
      <div class="login_btn_box_center">
        <div class="dis-flex3">
        <input type="checkbox" checked><div class="yes_check">我已阅读并同意<span class="color-blue">服务协议</span>和<span class="color-blue">隐私政策</span></div>
        </div>
        <div class="login_btn login_btn_next center color-fff" @click="nextBtn">下一步</div>
        <div class="center color-blue login_font fz14" @click="loginBtn">我要登录</div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive,toRefs } from 'vue'
import { sendMsg } from '@/api/login'
// import {useRouter } from 'vue-router'
import { ElNotification } from 'element-plus'
export default {
  name:'Useradmin',
  props:{
    types:Object,
    default:function(){
      return {
        type:3,
        phone:''
      }
    }
  },
  setup(props, ctx){
    // const router = useRouter()
    const state = reactive({
      phone:props.types.phone
    })
    const nextBtn=async()=>{
      await sendMsg({
        "phone": state.phone
      })
      ElNotification({
        title: 'Success',
        message: '注意手机短信',
        type: 'success',
        duration: 3000,
      })
      ctx.emit('changeTypes','3',state.phone)
      // router.push({ path: '/Login',query:{type:3}})
    }
    const loginBtn=()=>{
      ctx.emit('changeTypes','1')
      // router.push({ path: '/Login',query:{type:1}})
    }
    return {nextBtn,loginBtn,...toRefs(state),}
  }
}
</script>

<style lang="scss" scoped>
  .login_box{
    padding:60px;
    height:550px;
    box-sizing: border-box;
    .title_font{
      margin-bottom:32px;
    }
    .navigation_index{
      padding-top:32px;
    }
    
    .login_input{
      padding-top:22px;
    }
    .login_btn_box{
      position: absolute;
      bottom:60px;
      right:0;
      left:0;
      .login_btn_box_center{
        width:380px;
      }
      .login_btn{
        background:#0F6FFF;
        padding:12px 0;
        border-radius: 6px;
      }
      
      .yes_check{
        margin-left:10px;
      }
      .login_btn_next{
        margin:20px 0 16px 0;
        cursor: pointer;
      }
      .login_font{
        cursor: pointer;
      }
    }
    .admin_phone{
      margin-top:32px;
    }
    .admin_input{
      width:100%;
      border:1px solid #DDDEE1;
      padding:12px 16px;
      margin-bottom:22px;
      color:#999;
      border-radius: 6px;
    }
    .admin_input:focus{
      outline:none;
      border:1px solid #0F6FFF ;
      color:#333
    }
  }
</style>