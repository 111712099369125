<template>
  <div class="login_box pos-r" v-show="types=='1'">
    <div class="color333 fz24 fw600">登录钜汇</div>
    <div class="dis-flex6">
      <div class="navigation_index pos-r"><router-link to="/login/band?type=1" replace>品牌后台</router-link></div>
      <div class="navigation_index pos-r"><router-link to="/login/admin?type=1" replace>门店/经销商后台</router-link></div>
    </div>
    <div class="login_input">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name:'Loginadmin',
  props:{
    types:String,
  },
}
</script>

<style lang="scss" scoped>
   .login_box{
      padding:60px;
      height:550px;
      box-sizing: border-box;
      .navigation_index{
          padding-top:32px;
        }
      .login_input{
        padding-top:22px;
      }
      a {
        text-decoration:none;
        font-weight: bold;
        color: #666;
        font-size: 18px;
        padding:10px 15px;
        &.router-link-exact-active {
            color: #0F6FFF;
        }
        &.router-link-exact-active:before {
          content:'';
          display: block;
          position: absolute;
          bottom:-10px;
          left:50%;
          transform: translate(-50%,0);
          width:46px;
          height:4px;
          border-radius: 2px;
          background: #0F6FFF;
        }
      }
      .login_btn_box{
        position: absolute;
        bottom:60px;
        left:0;
        right:0;
        .login_btn{
          width:380px;
          background: #0F6FFF;
          padding:12px 0;
          border-radius: 6px;
        }
      }
   }
</style>